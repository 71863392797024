<template>
  <el-card class="box-card">
    <div slot="header">
      <span>{{ $tc('plan', 1) }}</span>
      <router-link
        class="card-header-button el-button el-button--primary el-button--mini"
        style="float: right"
        tag="button"
        to="/plans/create"
      >
        <i class="fad fa-plus"></i>&nbsp;{{ $t('create') }}
      </router-link>
    </div>
    <br />
    <el-tabs :key="1" v-model="tab.active">
      <el-tab-pane :label="$tc('searchByService')" name="1">
        <el-form :inline="false" :model="table.filter">
          <el-row :gutter="12">
            <el-col :xs="24" :sm="12" :lg="4">
              <el-form-item>
                <select-service
                  v-model="table.filter.serviceId"
                  :placeholder="$tc('service')"
                ></select-service>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :lg="4">
              <el-form-item>
                <el-input v-model="table.filter.planName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :lg="4">
              <el-form-item :label="' '">
                <el-button
                  size="mini"
                  type="primary"
                  :loading="table.loading"
                  @click="getData(1, 1)"
                >
                  {{ $tc('search') }}
                </el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-tab-pane>
      <el-tab-pane :label="$tc('searchByMode', 3)" name="2">
        <el-form :inline="false" :model="table.filter">
          <el-row :gutter="12">
            <el-col :xs="24" :sm="12" :lg="4">
              <el-form-item>
                <select-plan-mode v-model="table.filter.planMode">
                </select-plan-mode>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :lg="4">
              <el-form-item>
                <select-service
                  v-model="table.filter.serviceId"
                  :placeholder="$tc('service')"
                ></select-service>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :lg="4">
              <el-form-item>
                <el-input
                  v-model="table.filter.planName"
                  :placeholder="$tc('name', 0)"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :lg="3">
              <el-form-item>
                <el-switch
                  v-model="table.filter.planOnSite"
                  :active-text="$tc('onSite')"
                >
                </el-switch>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :lg="3">
              <el-form-item>
                <el-switch
                  v-model="table.filter.planEnabled"
                  :active-text="$tc('isEnabled')"
                >
                </el-switch>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :lg="4">
              <el-form-item :label="' '">
                <el-button
                  size="mini"
                  type="primary"
                  :loading="table.loading"
                  @click="getData(1, 2)"
                >
                  {{ $tc('search') }}
                </el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-tab-pane>
    </el-tabs>
    <br />
    <el-table
      v-loading="table.loading"
      :data="table.data"
      style="width: 100%"
      stripe
      border
    >
      <el-table-column :width="120" align="center">
        <template slot-scope="scope">
          <el-tooltip
            v-if="roleIsAdministrator"
            :content="$tc('update')"
            class="item"
            effect="dark"
            placement="top-start"
          >
            <el-button
              size="mini"
              circle
              icon="el-icon-edit"
              @click="onUpdate(scope.row.id)"
            ></el-button>
          </el-tooltip>
          <el-tooltip
            v-if="roleIsAdministrator"
            :content="`${$tc('edit')} ${$tc('status')}`"
            class="item"
            effect="dark"
            placement="top-start"
          >
            <el-button
              size="mini"
              circle
              icon="el-icon-delete"
              @click="onDelete({ data: { id: scope.row.id } })"
            ></el-button>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column :label="$tc('status')" :width="70" align="center">
        <template slot-scope="scope">
          <el-tooltip
            v-if="scope.row.planEnabled"
            :content="$tc('enabled')"
            class="item"
            effect="dark"
            placement="right"
          >
            <el-button size="mini" type="text">
              <i class="fad fa-circle text-success"></i>
            </el-button>
          </el-tooltip>
          <el-tooltip
            v-else
            :content="$tc('disabled')"
            class="item"
            effect="dark"
            placement="right"
          >
            <el-button size="mini" type="text">
              <i class="fad fa-circle text-danger"></i>
            </el-button>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column :width="50" align="center">
        <template slot-scope="scope">
          <el-tooltip
            v-if="scope.row.planMode.toLowerCase() === 'paquete'"
            content="Es un paquete"
            class="item"
            effect="dark"
            placement="right"
          >
            <el-button size="mini" type="text">
              <i class="fa-lg text-primary fas fa-box"></i>
            </el-button>
          </el-tooltip>
          <el-tooltip
            v-else-if="
              scope.row.planMode.toLowerCase() === 'paqueteperiodicidad'
            "
            content="Es un paquete para periodicidad"
            class="item"
            effect="dark"
            placement="right"
          >
            <el-button size="mini" type="text">
              <i class="fa-lg text-primary fas fa-cubes"></i>
            </el-button>
          </el-tooltip>
          <el-tooltip
            v-else-if="scope.row.planMode.toLowerCase() === 'periodicidad'"
            content="Es periodicidad"
            class="item"
            effect="dark"
            placement="right"
          >
            <el-button size="mini" type="text">
              <i class="fa-lg text-primary fas fa-calendar-alt"></i>
            </el-button>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        :label="$tc('order')"
        :width="60"
        align="center"
        prop="planOrder"
      ></el-table-column>
      <el-table-column :label="$tc('name')" :width="240">
        <template slot-scope="scope">
          {{ scope.row.planName }}
          <el-tooltip
            v-if="scope.row.planFree"
            content="Es gratis"
            class="item"
            effect="dark"
            placement="right"
          >
            <el-button size="mini" type="text">
              <i class="fas fa-star fa-lg text-warning"></i>
            </el-button>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        :label="$tc('service')"
        :width="120"
        align="center"
        prop="serviceName"
      ></el-table-column>
      <el-table-column :label="$tc('yearly')" align="right">
        <template slot-scope="scope">
          {{ scope.row.planPriceYearly | formatToMoney }}
        </template>
      </el-table-column>
      <el-table-column :label="$tc('monthly')" align="right">
        <template slot-scope="scope">
          {{ scope.row.planPriceMonthly | formatToMoney }}
        </template>
      </el-table-column>
      <el-table-column :label="$tc('quarterly')" align="right">
        <template slot-scope="scope">
          {{ scope.row.planPriceQuarterly | formatToMoney }}
        </template>
      </el-table-column>
      <el-table-column :label="$tc('semiannual')" align="right">
        <template slot-scope="scope">
          {{ scope.row.planPriceSemiannual | formatToMoney }}
        </template>
      </el-table-column>
      <el-table-column :label="$tc('tax')" align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.planTaxPercentIncluded">
            <span>{{ scope.row.planTaxPercentIncluded }}</span>
            /
            <small>{{ $tc('included') }}</small>
          </div>
          <div v-if="scope.row.planTaxPercentIncrease">
            <span>{{ scope.row.planTaxPercentIncrease }}</span>
            /
            <small>{{ $tc('increase') }}</small>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- <!~~ #region Pagination ~~> -->
    <el-pagination
      :current-page.sync="table.query.page"
      :page-size="table.query.limit"
      :page-sizes="[10, 20, 30, 40, 50]"
      :total="table.query.total"
      layout="total, sizes, prev, pager, next, jumper"
      @current-change="getData(table.query.page, table.filter.filterNumber)"
      @size-change="
        table.query.limit = $event
        getData(table.query.page, table.filter.filterNumber)
      "
    ></el-pagination>
    <!-- <!~~ #endregion ~~> -->
  </el-card>
</template>
<script>
import list, { table } from '@/views/common/list'
import { mapGetters } from 'vuex'

export default {
  extends: list('plans', { updateRoute: 'PlanUpdate' }),
  data() {
    return {
      tab: {
        active: '1',
      },
      table: {
        ...table(),
        ...{
          filter: {
            filterNumber: 1,
            serviceId: 2,
            planOnSite: true,
            planMode: 'Paquete',
            planEnabled: true,
          },
        },
      },
    }
  },
  computed: {
    ...mapGetters(['setting']),
    roleIsAdministrator: _this => [1, 2].includes(+_this.setting.roleId),
  },
  created() {
    this.getData(this.table.query.page, this.table.filter.filterNumber)
  },
  methods: {
    getData(pageNumber, filterNumber) {
      const _this = this
      _this.table.filter.filterNumber = filterNumber

      let queryStringParams = {}

      if (_this.table.filter.serviceId) {
        queryStringParams = {
          ...queryStringParams,
          serviceId: _this.table.filter.serviceId,
        }
      }

      if (_this.table.filter.planName) {
        queryStringParams = {
          ...queryStringParams,
          planName: _this.table.filter.planName,
        }
      }

      if (_this.table.filter.filterNumber > 1) {
        queryStringParams = {
          ...queryStringParams,
          planOnSite: _this.table.filter.planOnSite.toString(),
          planMode: _this.table.filter.planMode,
          planEnabled: _this.table.filter.planEnabled.toString(),
        }
      }

      _this.get('search', { pageNumber, queryStringParams })
    },
    showDialog(dialog) {
      const _this = this
      for (const dialogName in _this.dialog) {
        _this.dialog[dialogName].visible = false
      }
      if (_this.dialog[dialog]) {
        _this.dialog[dialog].visible = true
      }
    },
  },
}
</script>
