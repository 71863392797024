import { buildQueryString } from '@/helpers'
import { i18n } from '@/i18n'
import router from '@/router/'
import view from './view'

export default function (controller, config) {
  return {
    extends: view,
    methods: {
      async onDelete(params) {
        const _this = this
        let promiseDelete = new Promise(() => {})
        if (params.data && params.data.length > 0) {
          promiseDelete = _this.$confirm(i18n.tc('deleteManyMessage', [params.data.length]), i18n.tc('warning'), {
            confirmButtonText: i18n.tc('yes'),
            cancelButtonText: i18n.tc('no'),
            type: 'warning',
          })
        } else if (params.data.id) {
          promiseDelete = _this.$confirm(i18n.tc('deleteMessage'), i18n.tc('warning'), {
            confirmButtonText: i18n.tc('yes'),
            cancelButtonText: i18n.tc('no'),
            type: 'warning',
          })
        } else {
          promiseDelete = _this.$confirm(i18n.tc('deleteMessage', [params.entity.name]), i18n.tc('warning'), {
            confirmButtonText: i18n.tc('yes'),
            cancelButtonText: i18n.tc('no'),
            type: 'warning',
          })
        }
        promiseDelete.then(async _ => {
          _this.table.loading = true
          await _this.$http
            .delete(params.data.id ? `${controller}/${params.data.id}` : `${controller}`, {
              data: params.data.length > 1 ? params.data.map(item => item.id) : [],
            })
            .then(_ => {
              _this.$notify.success({
                title: i18n.tc('deleted'),
                message: i18n.tc('deletedSuccessfullyMessage'),
              })
              _this.get()
            })
            .finally(_ => (_this.table.loading = false))
        })
      },
      onChangeEnabled(id, attributeName, attributeValue, params) {
        const _this = this
        _this.table.loading = true
        let promise = new Promise(() => {})
        if (params && params.entities) {
          if (attributeName) {
            params.entities.forEach(element => {
              element[attributeName] = attributeValue
            })
          }
          let uri = ''
          if (params.actionName && id) {
            uri = `${controller}/${id}/${params.actionName}`
          } else if (params.actionName) {
            uri = `${controller}/${params.actionName}`
          } else if (id) {
            uri = `${controller}/${id}`
          } else {
            uri = `${controller}`
          }
          promise = _this.$http.patch(uri, params.entities)
        } else {
          promise = _this.$http.get(`${controller}/${id}`).then(response => _this.$http.put(`${controller}/${id}`, {
            ...response.data,
            [attributeName]: attributeValue,
          }))
        }

        return promise
          .then(_ => {
            _this.$notify.success({
              title: i18n.tc('update'),
              message: i18n.tc('updatedSuccessfullyMessage'),
            })
            _this.$refs.table.clearSelection()
          })
          .finally(_ => {
            _this.table.loading = false
            _this.get()
          })
      },
      onMultipleActions(command) {
        const _this = this
        if (command) {
          _this[command].bind(_this).call()
        }
      },
      onPagination() {
        this.get()
      },
      onSelection(value) {
        this.selectedItems = value
      },
      onUpdate(id) {
        router.push({ name: config.updateRoute, params: { id } })
      },
      get(actionName, config = {}) {
        const _this = this

        if (config && config.pageNumber) {
          _this.table.query.page = +config.pageNumber || _this.table.query.page
          _this.table.query.offset = (+_this.table.query.page - 1) * +_this.table.query.limit
        }
        const queryString = buildQueryString({
          ...(config.queryStringParams || {}),
          ...(_this.table.query && {
            page: _this.table.query.page,
            limit: _this.table.query.limit,
            offset: _this.table.query.offset.toString(),
            searchTerm: _this.table.query.searchTerm,
          }),
        })

        let uri = ''
        if (actionName) {
          uri = `${controller}/${actionName}?${queryString}`
        } else {
          uri = `${controller}?${queryString}`
        }

        _this.table.loading = true

        return _this.$http
          .get(uri)
          .then(response => {
            if (+response.data.count === 0 || +response.data.count > 0) {
              _this.table.data = response.data.data
              _this.table.query.total = response.data.count
            } else {
              _this.table.data = response.data
              _this.table.query.total = response.data.length
            }
          })
          .finally(_ => {
            _this.table.loading = false
          })
      },
    },
  }
}

export function table() {
  return {
    loading: false,
    data: [],
    filter: {},
    query: {
      page: 1,
      limit: 10,
      searchTerm: '',
      total: 0,
      offset: 0,
    },
  }
}
